import { Fragment, useCallback, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';

import { Dropdown, getColor, Switch } from 'basic-components';
import { CSV_HEADERS_TYPES, formatTypes } from 'dashboard-services';

import { TimeSeriesUtils } from 'utils';

import SectionTitle from '../SectionTitle';
import MetadataLabel from './MetadataLabel';

import { When } from 'react-if';

const OutputType = ({ formatType, csvHeaders, shouldTranspose, onChange, getKeys, withTranspose, withOutputType }) => {
  const callsRefs = useRef([]),
        localformatTypesArray = useMemo(() => [
          {
            label: formatTypes.NCSV,
            value: formatTypes.NCSV,
          },
          {
            label: formatTypes.CSV,
            value: formatTypes.CSV
          }
        ], []),
        localFormatType = useMemo(() => localformatTypesArray.find(f => f.value === formatType), [formatType, localformatTypesArray]),
        onChangeBool = useCallback((val, { name }) => onChange(String(val), { name }), [onChange]),
        onChangeHeader = useCallback((_, { name, data }) => onChange(data.map(obj => ({ name: obj.value, type: obj.type })), { name }), [onChange]),
        searchKeys = useCallback(input => 
          getKeys({ input, callsRefs })
            .then(response => TimeSeriesUtils.csvHeadersOptions.concat(response))
        , [getKeys]),
        defaultCsvHeaders = useMemo(() => csvHeaders?.map(header => {
            const defaultOne = TimeSeriesUtils.csvHeadersOptions.find(c => c.value === header.name)
            if(defaultOne) {
              return defaultOne
            } else {
              return {
                value: header.name, 
                label: header.type === CSV_HEADERS_TYPES.METADATA_KEY ? (<MetadataLabel name={header.name}/>) : header.name,
                color: header.type === CSV_HEADERS_TYPES.METADATA_KEY ? getColor("grey-dark") : undefined,
                type: header.type
              }
            }
          }), [csvHeaders])

  useEffect(() => () => {
    callsRefs.current.forEach(promiseObj => promiseObj?.cancel())
  }, [])

  return (
    <Fragment>
      <SectionTitle>
        CONFIGURATION
      </SectionTitle>
      <When condition={withOutputType}>
        {() => (
          <Fragment>
            <div className="ng-office-app__authed__content__body__item__expanded-settings__bottom__label">
              Output type
            </div>
            <div className="ng-office-app__authed__content__body__item__expanded-settings__bottom__value">
              <Dropdown
                  defaultValue={localFormatType}
                  fixed
                  menuPortalTarget={document.querySelector('.ng-office-app')}
                  name="formatType"
                  noError
                  onChange={onChange}
                  options={localformatTypesArray}
                  variant="border"
              />
            </div>
          </Fragment>
        )}
      </When>
      <When condition={formatType === formatTypes.CSV}>
        {() => (
          <Fragment>
            <div className="ng-office-app__authed__content__body__item__expanded-settings__bottom__label">
              Column header
            </div>
            <div className="ng-office-app__authed__content__body__item__expanded-settings__bottom__value">
              <Dropdown
                  async
                  defaultValue={defaultCsvHeaders}
                  fixed
                  loadOptions={searchKeys}
                  menuPortalTarget={document.querySelector('.ng-office-app')}
                  multi
                  name="csvHeaders"
                  noError
                  onChange={onChangeHeader}
                  openMenuOnFocus
                  variant="border"
              />
            </div>
          </Fragment>
        )}
      </When>
      <When condition={withTranspose}>
        {() => (
          <div className="ng-office-app__authed__content__body__item__expanded-settings__bottom__switch">
            <div className="ng-office-app__authed__content__body__item__expanded-settings__bottom__label">
              Transpose the result
            </div>
            <div className="ng-office-app__authed__content__body__item__expanded-settings__bottom__value">
              <Switch
                  name="shouldTranspose"
                  noError
                  on={shouldTranspose === "true"}
                  onChange={onChangeBool}
              />
            </div>
          </div>
        )}
      </When>
    </Fragment>
  )
}

OutputType.defaultProps = {
  csvHeaders: undefined,
  formatType: undefined,
  shouldTranspose: "false",
  withOutputType: false,
  withTranspose: false
}

OutputType.propTypes = {
  csvHeaders: PropTypes.array, 
  formatType: PropTypes.string, 
  getKeys: PropTypes.func.isRequired, 
  onChange: PropTypes.func.isRequired, 
  shouldTranspose: PropTypes.string, 
  withOutputType: PropTypes.bool,
  withTranspose: PropTypes.bool, 
}

export default OutputType;