import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { Button, FileArrivalTrigger } from 'basic-components';
import { globalActions } from 'primary-components';

import './SetAsDefaultSettings.scss';

const SetAsDefaultSettings = ({ onSave }) => {
  const dispatch = useDispatch(),
        saveDefaultSettings = useCallback(() => {
          onSave()
          dispatch(globalActions.getMessageStore()).success("Default settings have been set.")
        }, [dispatch, onSave])

  return (
    <div 
        className="ng-office-app__authed__content__body__item__expanded-settings__bottom__set-as-default"
        onClick={saveDefaultSettings}
    >
      <div className="ng-office-app__authed__content__body__item__expanded-settings__bottom__set-as-default__icon">
        <FileArrivalTrigger
            color="blue-bright"
            height={16}
            width={16}
        />
      </div>
      <Button
          size="small"
          variant="text"
      >
        Set as default settings
      </Button>
    </div>
  )
}

SetAsDefaultSettings.propTypes = {
  onSave: PropTypes.func.isRequired
}

export default SetAsDefaultSettings;