import { Fragment, useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import CollapsedSettings from './CollapsedSettings';
import ExpandedSettings from './ExpandedSettings';

import { When } from 'react-if';

const TsSettings = ({ children }) => {
  const [areSettingsOpen, setAreSettingsOpen] = useState(false),
        openSettings = useCallback(() => setAreSettingsOpen(true), []),
        closeSettings = useCallback(() => setAreSettingsOpen(false), [])

  return (
    <Fragment>
      <CollapsedSettings openSettings={openSettings}/>
      <When condition={areSettingsOpen}>
        {() => (
          <ExpandedSettings 
              children={children}
              closeSettings={closeSettings}
          />
        )}
      </When>
    </Fragment>
  )
}

TsSettings.propTypes = {
  children: PropTypes.any.isRequired,
}

export default TsSettings;