import { CommonService } from 'dashboard-services';

import properties from 'resources/constants/properties.json';

export default (() => {

  const setApiUrl = apiUrl => {
    localStorage.setItem("ng-api-url", apiUrl);
  }

  const getApiUrl = () => {
    const apiUrl = localStorage.getItem("ng-api-url");
    if(apiUrl === "undefined" || apiUrl === "null" || apiUrl === null) {
      return properties.baseUrl.api
    }
    return apiUrl;
  }

  const setTsDefaultSettings = defaultSettings => {
    localStorage.setItem("ng-default-ts-settings", JSON.stringify(defaultSettings));
  }

  const setCurveDefaultSettings = defaultSettings => {
    localStorage.setItem("ng-default-curve-settings", JSON.stringify(defaultSettings));
  }

  const getTsDefaultSettings = () => {
    const settingsFromLS = localStorage.getItem("ng-default-ts-settings")
    return CommonService.parseJson(settingsFromLS, {})
  }

  const getCurveDefaultSettings = () => {
    const settingsFromLS = localStorage.getItem("ng-default-curve-settings")
    return CommonService.parseJson(settingsFromLS, {})
  }

  const clearLocalStorage = () => {
    const apiUrl = getApiUrl()
    const tsDefaultSettings = getTsDefaultSettings()
    const curveDefaultSettings = getCurveDefaultSettings()
    localStorage?.clear()
    setApiUrl(apiUrl)
    setTsDefaultSettings(tsDefaultSettings)
    setCurveDefaultSettings(curveDefaultSettings)
  }

  return {
    setApiUrl,
    getApiUrl,
    setTsDefaultSettings,
    getTsDefaultSettings,
    clearLocalStorage,
    setCurveDefaultSettings,
    getCurveDefaultSettings
  }
})()