import { Fragment, memo, useCallback, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';

import { Dropdown, getColor, Radio, RadioGroup, Switch } from 'basic-components';
import { CSV_HEADERS_TYPES, tsOrder } from 'dashboard-services';

import MetadataLabel from '../OutputType/MetadataLabel';
import SectionTitle from '../SectionTitle';

import { When } from 'react-if';

const ColumnsOrder = ({ onChange, getKeys, sortKeys, sortKeysBy, sortKeysOrder }) => {
  const callsRefs = useRef([]),
        onChangeSortBy = useCallback((_, { name, data }) => onChange(data ? { key: data.value, type: data.type } : undefined, { name }), [onChange]),
        onChangeBool = useCallback((val, { name }) => onChange(String(val), { name }), [onChange]),
        searchKeys = useCallback(input => getKeys({ input, callsRefs }), [getKeys]),
        defaultSortKeysBy = useMemo(() => sortKeysBy ? ({
          value: sortKeysBy.key, 
          label: sortKeysBy.type === CSV_HEADERS_TYPES.METADATA_KEY ? (<MetadataLabel name={sortKeysBy.key}/>) : sortKeysBy.key,
          color: sortKeysBy.type === CSV_HEADERS_TYPES.METADATA_KEY ? getColor("grey-dark") : undefined,
          type: sortKeysBy.type
        }) : undefined, [sortKeysBy])

  useEffect(() => () => {
    callsRefs.current.forEach(promiseObj => promiseObj?.cancel())
  }, [])

  return (
    <Fragment>
      <SectionTitle>
        DATA SORTING
      </SectionTitle>
      <div className="ng-office-app__authed__content__body__item__expanded-settings__bottom__switch ng-office-app__authed__content__body__item__expanded-settings__bottom__switch--is-small-margin">
        <div className="ng-office-app__authed__content__body__item__expanded-settings__bottom__label">
          Sort keys
        </div>
        <div className="ng-office-app__authed__content__body__item__expanded-settings__bottom__value">
          <Switch
              name="sortKeys"
              noError
              on={sortKeys === "true"}
              onChange={onChangeBool}
          />
        </div>
      </div>
      <When condition={sortKeys === "true"}>
        {() => (
          <Fragment>
            <div className="ng-office-app__authed__content__body__item__expanded-settings__bottom__label">
              Sort keys by
            </div>
            <div className="ng-office-app__authed__content__body__item__expanded-settings__bottom__value">
                <Dropdown
                    async
                    defaultValue={defaultSortKeysBy}
                    fixed
                    loadOptions={searchKeys}
                    menuPortalTarget={document.querySelector('.ng-office-app')}
                    name="sortKeysBy"
                    noError
                    onChange={onChangeSortBy}
                    openMenuOnFocus
                    variant="border"
                />
            </div>
            <div className="ng-office-app__authed__content__body__item__expanded-settings__bottom__label">
              Sort keys order
            </div>
            <div className="ng-office-app__authed__content__body__item__expanded-settings__bottom__value ng-office-app__authed__content__body__item__expanded-settings__bottom__value--radio">
              <RadioGroup
                  name="sortKeysOrder"
                  onChange={onChange}
                  selectedValue={Object.values(tsOrder).includes(sortKeysOrder) ? sortKeysOrder : tsOrder.ASC}
              >
                <Radio name={tsOrder.ASC}>
                  Ascending
                </Radio>
                <Radio name={tsOrder.DESC}>
                  Descending
                </Radio>
              </RadioGroup>
            </div>
          </Fragment>
        )}
      </When>
    </Fragment>
  )
}

ColumnsOrder.defaultProps = {
  sortKeys: undefined,
  sortKeysBy: undefined,
  sortKeysOrder: undefined
}

ColumnsOrder.propTypes = {
  getKeys: PropTypes.func.isRequired, 
  onChange: PropTypes.func.isRequired, 
  sortKeys: PropTypes.string, 
  sortKeysBy: PropTypes.object, 
  sortKeysOrder: PropTypes.string
}

export default memo(ColumnsOrder)